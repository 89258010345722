import development from "./aws-exports-files/aws-exports";
import staging from "./aws-exports-files/aws-exports-staging";
import production from "./aws-exports-files/aws-exports-production";

let awsExports:any;

switch (process.env.REACT_APP_ENVIRONMENT) {
    case "DEVELOPMENT":
        awsExports = development
        break;
    case "STAGING":
        awsExports = staging
        break;
    case "PRODUCTION":
        awsExports = production
}

export default awsExports;