import { useMutation, useQuery } from "@apollo/client";
import { Auth } from "aws-amplify";
import USER_ORG_REFERRAL_SUBSCRIPTION from "../GraphQL/UserOrganizationReferallSubscription/user-org-referral-subscription";

export const handleAWSConfirmEmail = async (email: any, password: any, code: any) => {
    const username = email.toLowerCase();
    try{
        const response = await Auth.confirmSignUp(
            username,
            code
        );

        console.log(response)
        if(response === "SUCCESS") {
            // const {data, error, loading } = useQuery(USER_ORG_REFERRAL_SUBSCRIPTION, {})
            return await handleAWSLogIn(email, password);
        }
        return { status: "unknownAuthError"};
    } catch (error:any) {
        console.log(error.code, error);
        if(error.code === "CodeMismatchException") {
            return {status: "confirmSignUp", authParameters: {
                errors: {
                    confirmationCode: "Incorrect Auth Code"
                }
            }}
        }
        
    }
    return {status: "unknown"}
}

export const handleAWSLogIn = async (email: string, password: string) => {
    const username = email.toLowerCase()
    try{
        const response = await Auth.signIn({
            username,
            password
        });


        if(response) {
            return {status: "signedIn"}
        }
        return {status: "unknownAuthError"}
    } catch (err:any) {
        if(err.code === "UserNotConfirmedException") {
            return {
                status: "confirmSignUp",
                authParameters: {
                    email,
                    password
                }
            }
        }
        if(err.code === "NotAuthorizedException") {
            return { 
                status: "logIn",
                authParameters: {
                    errors: {
                        email: "Incorrect Username or Password.", 
                        password: "Incorrect Username or Password."
                    }
                }
            }
        }
    }
}

export const handleAWSSignUp = async (email:any, password:any, phone_number:any, first_name:any, last_name:any) => {
    try {
        const username = email.toLowerCase();

        console.log("Sending Sign Up with info: ", {
            username,
            password: password,
            attributes: {
                email: username,
                phone_number: "+" + phone_number,
                name: first_name + " " + last_name,
                given_name: first_name,
                family_name: last_name
            }
        })
        
        const {user} = await Auth.signUp({
            username,
            password: password,
            attributes: {
                email: username,
                phone_number: "+" + phone_number,
                name: first_name + " " + last_name,
                given_name: first_name,
                family_name: last_name
            }
        });
        if(user) return { status: "confirmSignUp", authParameters: {email: username, password}};
        return { status: "unknownAuthError"};
    } catch (error:any) {
        if(error.code === 'UsernameExistsException') {
            return {status: "signUp", authParameters: {
                errors: {
                    email: "Account With Email Already Exists"
                }
            }}
        }
        return {status: error.code}
    }
}