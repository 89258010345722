import { gql } from "@apollo/client";

const USER_ORG_REFERRAL_SUBSCRIPTION = gql`
  mutation MyMutation($input: UserOrgReferralSubscription!) {
    userOrgReferralSubscription(input: $input) {
      email
    }
  }
`;

export default USER_ORG_REFERRAL_SUBSCRIPTION;

// EX:
// {
//   "input": {
//     "email": "john@gmail.com",
//     "referral_code": "american-red-cross"
//   }
// }