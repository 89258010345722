import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay, faAppStoreIos} from '@fortawesome/free-brands-svg-icons';

type DownloadInstructionsProps = {
}

const DownloadInstructions: React.FC = (props: DownloadInstructionsProps) => {
    return (<Container component="main" maxWidth="xs" sx={{textAlign: "center"}}>
        <CssBaseline />
        <Box
        sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
            <Box component="img" mb={3} sx={{width: 320}} alt="CivAction Logo" src='./CivActionLogo.png'/>

            <Box component="div">
                <Typography component="p">Thank you for signing up for CivAction!</Typography>
                <Typography component="p">To download the application, please click on one of the links below!
                </Typography>
                <Box component="div" mt={3}>
                    <Button fullWidth
                        variant="contained"
                        sx={{ mb: 3 }}
                        startIcon={<FontAwesomeIcon icon={faGooglePlay} style={{height: 24, width:24}}/>}
                    >
                        
                        Download for Android
                    </Button>
                    <Button fullWidth
                        variant="contained"
                        sx={{ mb: 3 }}
                        startIcon={<FontAwesomeIcon icon={faAppStoreIos} style={{height: 24, width:24}}/>}
                    >
                        Download for Mac/iOS
                    </Button>
                </Box>
            </Box>
        </Box>
    </Container>)
}

export default DownloadInstructions;