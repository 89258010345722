const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hn2kf4TzG",
    "aws_user_pools_web_client_id": "301hull73g3lu64kvu70jic7m9",
    "oauth": {
        "domain": "domain-staging-stage-main.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://referrals.staging.civaction.com/",
        "redirectSignOut": "https://referrals.staging.civaction.com/",
        // "redirectSignIn": "exp://127.0.0.1:19000/",
        // "redirectSignOut": "exp://127.0.0.1:19000/", 
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://staging.civaction.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5jnu6xlnyrfa3f4kvv2tgcpdum",
};

export default awsmobile;