import { gql } from "@apollo/client";

const GET_ORGANIZATION_BY_REFERRAL_CODE = gql`
  query MyQuery($input: GetOrganizationByReferralCodeInput!) {
    GetOrganizationByReferralCode(input: $input) {
      name
      thumbnail_image_file_location
    }
  }
`;

export default GET_ORGANIZATION_BY_REFERRAL_CODE;

// EX:

// {
//   "input": {
//     "referral_code": "american-red-cross"
//   }
// }