import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom"
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import FacebookIcon from '@mui/icons-material/Facebook';
import "./SignUp" ;
import { Avatar } from '@mui/material';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons'
import { AuthResponse, ErrorStates } from './util/types';
import {handleAWSSignUp} from "./util/Auth";
import { useQuery } from '@apollo/client';
import GET_ORGANIZATION_BY_REFERRAL_CODE from './GraphQL/GetOrganizationByReferral/get-organization-by-referral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Auth} from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import GET_USER_BY_REFERRAL_CODE from './GraphQL/GetUserByReferral/get-user-by-referral';

library.add(fas, faTwitter, faFontAwesome, faGooglePlay, faApple)

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://civaction.com/">
        CivAction
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

type SignUpProps = {
    handleAuthResponse: (response: AuthResponse) => any;
    authErrors: any
}

export default function SignUp(props: SignUpProps) {

    const {referralCode} = useParams();

    const {handleAuthResponse, authErrors} = props;
    const [phone, setPhone] = React.useState<string>('');
    const [errors, setErrors] = React.useState<ErrorStates>({});

    const { data: organizationInfo, loading: organizationLoading, error: organizationError} = useQuery<any>(GET_ORGANIZATION_BY_REFERRAL_CODE, { variables: { input: { referral_code: referralCode}}});
    const { data: userInfo, loading: userLoading, error: userError} = useQuery<any>(GET_USER_BY_REFERRAL_CODE, {variables: { input: { referral_code: referralCode}}})

    React.useEffect(() => {
        console.log(referralCode);
        console.log(organizationInfo);
        console.log(userInfo);
    }, [organizationInfo, userInfo])
    //TODO: Make Call to the database to get an organization's Name and Image Location from the referralCode

    //TODO: FIXIMAGE LOCATION
    const imageInfo = { alt: "Red Cross", imageLocation: "https://www.logodesignlove.com/wp-content/uploads/2011/10/red-cross-logo.jpg"}
    const referringEntity = {
        name: "Red Cross"
    }

    React.useEffect(() => {
        setErrors(Object.assign({}, authErrors));
    }, [authErrors])
    
    const onChange = (name: string) => {
        if(errors[name]) {
            const newErrors = Object.assign({}, errors);
            delete newErrors[name];
            setErrors(newErrors);
        }
    }

    const validate = (event: React.FormEvent<HTMLFormElement>): FormData | undefined => {
        const currentErrors: ErrorStates = {};
        const data = new FormData(event.currentTarget);
        if(!validateEmail(data.get('email'))) {
            currentErrors['email'] = "Invalid Email Address";
        }
        if(phone.length < 9) {
            currentErrors['phone'] = "Invalid Phone Number";
        }

        if(data.get('password') !== data.get('confirmPassword')) {
            currentErrors['confirmPassword'] = "Passwords Do Not Match"
        }

        setErrors(currentErrors);
        if(Object.keys(currentErrors).length > 0) return undefined;
        return data;

    }

    const validateEmail = (email:FormDataEntryValue | null) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data:FormData | undefined = validate(event);
        if(!data) return;
        
        handleAWSSignUp(data.get('email'), data.get('password'), phone, data.get('firstName'), data.get('lastName')).then((authResponse: AuthResponse) => {
            console.log("AUTH RESPONSE: ", authResponse)
            handleAuthResponse(authResponse);
        })
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        //     phone,
        //     firstName: data.get('firstName'),
        //     lastName: data.get('lastName'),
        // });
    };

    const handlePhoneChange = (phoneNumber:any ) => {
        onChange('phone')
        if(phoneNumber && phoneNumber !== "") {
            setPhone(phoneNumber.replace(/[^0-9.]/g, ""))
        } 
    }

    return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
            <Container component="main" maxWidth="xs" sx={{}}>
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Box component="img" mb={3} sx={{width: 320}} alt="CivAction Logo" src='./CivActionLogo.png'/>  
                    {/* Organizational Referral Info */}
                    {organizationInfo?.GetOrganizationByReferralCode && <Box component="div" alignItems='center' display={"flex"} flexDirection="column">
                        {organizationInfo?.GetOrganizationByReferralCode.thumbnail_image_file_location && <Avatar sx={{ width: 96, height: 96, border: 1, marginBottom: 3}} alt={imageInfo.alt} src={organizationInfo?.GetOrganizationByReferralCode.thumbnail_image_file_location}/>}
                        <Typography>Referrer: <strong>{organizationInfo?.GetOrganizationByReferralCode.name}</strong></Typography>
                    </Box>}
                    {organizationInfo?.GetOrganizationByReferralCode && <Typography component="p" align='center' mt={1}>
                        Weclome to CivAction. When you complete the signup process, you will be all set to take action with <strong>{organizationInfo?.GetOrganizationByReferralCode.name}</strong>
                    </Typography>}

                    {/* User Referral Code */}
                    {/* {userInfo?.GetUserByReferralCode && <Box component="div" alignItems='center' display={"flex"} flexDirection="column">
                        {userInfo?.GetUserByReferralCode.thumbnail_image_file_location && <Avatar sx={{ width: 96, height: 96, border: 1, marginBottom: 3}} alt={imageInfo.alt} src={userInfo?.GetUserByReferralCode.thumbnail_image_file_location}/>}
                        <Typography>Referrer: <strong>{userInfo?.GetUserByReferralCode.first_name}</strong> <strong>{userInfo?.GetUserByReferralCode.last_name}</strong></Typography>
                    </Box>} */}
                    {userInfo?.GetUserByReferralCode && <Typography component="p" align='center' mt={1}>
                        Weclome to CivAction. When you complete the signup process, you will be all set to take action with your referrer: <strong>{userInfo?.GetUserByReferralCode.first_name}</strong> <strong>{userInfo?.GetUserByReferralCode.last_name}</strong>
                    </Typography>}

                    {/* Start Federated Identity Inputs */}
                    <Box component="div" sx={{mt:3, height: 46, width: "100%"}} onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})}>
                        <Button className='googleButton' variant='contained' size="large" startIcon={<Box component="img" sx={{
                                width: 24,
                                height: 24
                            }}
                            alt="Google Icon"
                            src={"./Google.png"} />} sx={{
                            height: 46,
                            width: "100%",
                            backgroundColor: "white",
                            color: "#323232"
                        }}>
                            <Typography component="p" ml={2} >Continue with Google</Typography>
                        </Button>
                    </Box>
                    <Box component="div" sx={{mt:2, width: "100%"}}>
                        <Button className='facebookButton' startIcon={<FacebookIcon sx={{fontSize: "30px !important", color: "#4267B2", marginLeft: 2}}/>} variant='contained' size="large" onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook})} sx={{
                            height: 46,
                            width: "100%",
                            backgroundColor: "white",
                            color: "#323232"
                        }}>
                            <Typography component="p" ml={2} >Continue with Facebook</Typography>
                        </Button>
                    </Box>
                    <Box component="div" sx={{mt:2, height: 46, width: "100%"}}>
                        <Button className='appleButton' variant='contained' size="large" startIcon={<FontAwesomeIcon icon={faApple} style={{height: 24, width:24}}/>} onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple})} sx={{
                            height: 46,
                            width: "100%",
                            backgroundColor: "white",
                            color: "#323232"
                        }}>
                            <Typography component="p" ml={2} >Continue with Apple</Typography>
                        </Button>
                    </Box>
                    <Box component="div" mt={3}>
                        <Typography component="p">OR</Typography>
                    </Box>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            autoComplete="given-name"
                            name="firstName"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            autoFocus
                            error={!!errors['firstName']}
                            helperText={errors['firstName']}
                            onChange={() => onChange('firstName')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            error={!!errors['lastName']}
                            helperText={errors['lastName']}
                            onChange={() => onChange('lastName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPhoneNumber 
                                defaultCountry='us' 
                                required 
                                fullWidth 
                                id="phone" 
                                label="Phone Number" 
                                name="phone" 
                                variant='outlined' 
                                onChange={handlePhoneChange}
                                error={!!errors['phone']}
                                helperText={errors['phone']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            error={!!errors['email']}
                            helperText={errors['email']}
                            onChange={() => onChange('email')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            error={!!errors['password']}
                            helperText={errors['password']}
                            onChange={() => onChange('password')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="confirm-password"
                            error={!!errors['confirmPassword']}
                            helperText={errors['confirmPassword']}
                            onChange={() => onChange('confirmPassword')}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                            label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                        </Grid>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Sign Up
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}