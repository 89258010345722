import { gql } from "@apollo/client";

const GET_USER_BY_REFERRAL_CODE = gql`
  query MyQuery($input: GetUserByReferralCodeInput!) {
    GetUserByReferralCode(input: $input) {
        first_name
        last_name
        referral_code
    }
  }
`;

export default GET_USER_BY_REFERRAL_CODE;
