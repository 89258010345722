import * as React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {AuthResponse, ErrorStates} from "./util/types"
import { Input } from "@mui/material";
import { Link } from "@mui/material";
import { handleAWSConfirmEmail } from "./util/Auth";

type ConfirmationCodeProps = {
    email: string,
    password: string,
    handleAuthResponse: (authResponse: AuthResponse) => any,
    authErrors: any
}

function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://civaction.com/">
          CivAction
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  

const ConfirmationCode:React.FC<ConfirmationCodeProps> = (props: ConfirmationCodeProps) => {

    const {email, password, handleAuthResponse, authErrors} = props;
    const [confirmationCode, setConfirmationCode] = React.useState('');

    const [errors, setErrors] = React.useState<ErrorStates>({});

    React.useEffect(() => {
        setErrors(Object.assign({}, authErrors));
    }, [authErrors])

    const onChange = (name: string, event:any) => {
        if(name=== 'confirmationCode') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            setConfirmationCode(onlyNums);
        }
        if(errors[name]) {
            const newErrors = Object.assign({}, errors);
            delete newErrors[name];
            setErrors(newErrors);
        }
    }

    const validate = (event: React.FormEvent<HTMLFormElement>): FormData | undefined => {
        const currentErrors: ErrorStates = {};
        const data = new FormData(event.currentTarget);
        const code = data.get('confirmationCode')?.toString();
        console.log(code);
        
        if(code && code.length < 6) {
            currentErrors['confirmationCode'] = "Invalid Confirmation Code."
        }
        setErrors(currentErrors);
        if(Object.keys(currentErrors).length > 0) return undefined;
        return data;

    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data:FormData | undefined = validate(event);
        if(!data) return;
        handleAWSConfirmEmail(data.get('email'), data.get('password'), data.get('confirmationCode')).then((authResponse: any) => {
            console.log("AUTH RESPONSE: ", authResponse);
            
            handleAuthResponse(authResponse);
        })
        console.log({
            email: data.get('email'),
            password: data.get('password'),
            code: data.get('confirmationCode')
        });
    };

    return (<Container component="main" maxWidth="xs" sx={{}}>
    <Box
    sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}
    >
        <Box component="img" mb={3} sx={{width: 320}} alt="CivAction Logo" src='./CivActionLogo.png'/>
        <Typography component="p" align='center' mt={1}>
            Please confirm your email before continuing. A confirmation code has been sent to: {email || "example@gmail.com"}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="confirmationCode"
                        label="Confirmation Code"
                        id="confirmationCode"
                        type="text"
                        value={confirmationCode}
                        sx={{width: "100%"}}
                        error={!!errors['confirmationCode']}
                        helperText={errors['confirmationCode']}
                        onChange={(e) => onChange('confirmationCode', e)}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                        label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                </Grid> */}
                <Input
                    required
                    fullWidth
                    disableUnderline={true}
                    value={email}
                    id="email"
                    name="email"
                    type="hidden"
                    autoComplete="email"
                    error={!!errors['email']}
                    />
                    <Input
                    required
                    fullWidth
                    disableUnderline={true}
                    value={password}
                    name="password"
                    type="hidden"
                    id="password"
                    autoComplete="new-password"
                    error={!!errors['password']}
                    />
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                Confirm Email
                </Button>
        </Box>
    </Box>
    <Copyright sx={{ mt: 5 }} />
</Container>);
}

export default ConfirmationCode;