import { Fragment, useState } from 'react';
import './App.css';
import { AuthResponse } from './util/types';
import SignUp from './SignUp';
import ConfirmationCode from './ConfirmationCode';
import awsmobile from "./util/AWS-Exports";
import Amplify from 'aws-amplify';
import DownloadInstructions from './DownloadInstructions';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import USER_ORG_REFERRAL_SUBSCRIPTION from './GraphQL/UserOrganizationReferallSubscription/user-org-referral-subscription';

function App() {

  const { referralCode } = useParams();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [apiErrors, setApiErrors] = useState<any>({})
  const [authStatus, setAuthStatus] = useState<string | undefined>('signUp');

  const [subscribeUserToOrganization, { data: goalSubscription }] = useMutation(
    USER_ORG_REFERRAL_SUBSCRIPTION
  );

  const subUserToOrganization = () => {
    subscribeUserToOrganization({
      variables: {
        input: {
          email,
          referral_code: referralCode
        }
      }
    })
  }

  const handleAuthResponse = (response: AuthResponse) => {
    if(response.authParameters?.email) setEmail(response.authParameters.email); 
    if(response.authParameters?.password) setPassword(response.authParameters.password);
    setApiErrors(response.authParameters?.errors);
    setAuthStatus(response.status);
    console.log("AUTH RESPONSE: ", response)
    if(response.status === "signedIn") {
      subUserToOrganization()
      //UseMutation here for email and orgReferralCode
    }
  }

  const awsMobileSettings = {
    ...awsmobile,
    oauth: {
      ...awsmobile.oauth,
    },
    Analytics: {
      disabled: true
    }
  }
  Amplify.configure(awsMobileSettings)



  return (

    <Fragment>
      {authStatus === 'signUp' && <SignUp handleAuthResponse={handleAuthResponse} authErrors={apiErrors}/>}
      {authStatus === 'confirmSignUp' && <ConfirmationCode email={email} password={password}  handleAuthResponse={handleAuthResponse} authErrors={apiErrors}/>}
      {authStatus === 'signedIn' && <DownloadInstructions />}
    </Fragment>
  );
}


export default App;
